import { handleErrors } from 'functions/Errors';
import { apiWrapper } from 'config/api';
/**
 * Получение списка специальностей
 * @param params - объект ключа запроса
 * @param params.queryKey - url запроса и номер страницы
 */
export const getSpecialities = async ({ queryKey }) => {
    const queryUrl = queryKey[0];
    const response = await apiWrapper.get(queryUrl);
    if (!response.ok) {
        throw new Error(handleErrors(response));
    }
    return response.data || {};
};
