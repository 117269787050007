/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, ClickAwayListener, IconButton, Stack, useTheme } from '@mui/material';
import ButtonsMain from 'components/buttons/Main';
import ButtonsText from 'components/buttons/Text';
import ModalsLayout from 'components/modals/Layout';
import { useCustomAlerts } from 'helpers/hooks/useCustomAlerts';
import { IconBasket, IconChecked, IconExclamationMark, IconOkay, IconPen, IconPlus } from 'svg/common';
import { useDeleteSpecialityMutation } from 'actions/specialities/useDeleteSpecialityMutation';
const maxLength = 50;
/**
 * Строка таблицы для страницы add-специальностей
 * @param root0 - пропсы
 * @param root0.speciality - Специальность
 * @param root0.countSpecialities - Назначено специальностей
 * @param root0.allSpeciality - список всех специальностей
 * @param root0.saveEdit - ф-ия для сохранения редактирования
 * @param root0.isAddNewSpeciality - флаг, показывающий, что это строка добавления новой специальности
 * @param root0.addSpeciality - ф-ия для добавления специализации
 */
export const SettingsSpecialityEditDeleteTableRow = ({ speciality, countSpecialities, allSpeciality, saveEdit = () => { }, addSpeciality = () => { }, isAddNewSpeciality = false }) => {
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Флаг, показывается ли модалка про удаление
    const [isEdit, setIsEdit] = useState(false); // Флаг, включено ли редактирование
    const [inputValue, setInputValue] = useState(''); // Значение в инпуте
    const [isDuplicate, setIsDuplicate] = useState(false); // Флаг, является ли строка в инпуте копией другой специальности
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef(null);
    const { palette } = useTheme();
    const { mutate: deleteSpecialityMutation } = useDeleteSpecialityMutation();
    const { showCustomAlert } = useCustomAlerts();
    useEffect(() => {
        if (!isAddNewSpeciality)
            setInputValue(speciality.name);
    }, [speciality]);
    useEffect(() => {
        setIsDuplicate(allSpeciality.map(special => special.name)
            .some(value => inputValue.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim()));
        if (inputValue.length > maxLength)
            setInputValue(inputValue.slice(0, maxLength));
    }, [inputValue]);
    useEffect(() => {
        if ((isEdit && ref.current) || (isAddNewSpeciality && ref.current))
            ref.current.focus();
    }, [isEdit]);
    /**
     * Функция закрывает модалку про удаление
     */
    const closeDeleteModal = () => {
        setIsShowDeleteModal(false);
    };
    /**
     * Функция выполняется при сохранении редактирования/добавлении новой специализации
     */
    const onSave = () => {
        setIsLoading(true);
        const addFunc = {
            /**
             * Ф-ия выполнится при успешном обновлении
             */
            onSuccess: () => setIsEdit(false),
            /**
             * Ф-ия выполнится при ошибке
             */
            onError: () => setIsDuplicate(true),
            /**
             * Ф-ия выполнится при любом исходе
             */
            onSettled: () => setIsLoading(false)
        };
        // Если у нас строка на добавление, то вызываем ф-ию addSpeciality, иначе saveEdit
        if (isAddNewSpeciality) {
            addSpeciality(inputValue, addFunc);
        }
        else {
            saveEdit(speciality.id, inputValue, addFunc);
        }
    };
    /**
     * Функция выполняется при удалении специализации
     * @param id - идентификатор специализации
     */
    const onDelete = (id) => {
        setIsLoading(true);
        deleteSpecialityMutation({ id }, {
            /**
             * Ф-ия выполнится при успешной мутации
             */
            onSuccess: () => {
                showCustomAlert('Специальность успешно удалена', { className: '!text-green', icon: React.createElement(IconChecked, null) });
            },
            /**
             * Ф-ия выполнится при ошибке удаления
             * @param e - объект ошибки при удалении
             */
            onError: (e) => {
                showCustomAlert(`${e.message}. Попробуйте ещё раз`, { className: '!text-red', icon: React.createElement(IconExclamationMark, null), severity: 'error' });
            },
            /**
             * Ф-ия выполнится при любом исходе удаления
             */
            onSettled: () => setIsLoading(false)
        });
    };
    /**
     * Проверка, нужно ли деактивировать кнопку сохранения при редактировании
     */
    const isDeactivateButton = () => (isDuplicate || inputValue.length === 0);
    /**
     * Проверка, включен ли режим редактирования или это добавление новой специализации (для
     * новой специализации режим редактирования всегда включен)
     */
    const isEditOrAddNew = (isEdit || isAddNewSpeciality);
    /**
     * Рендер кнопок
     */
    const renderButtons = () => (isEditOrAddNew ? (React.createElement(IconButton, { sx: {
            p: 0,
            pointerEvents: isDeactivateButton() ? 'none' : 'auto'
        }, onClick: () => onSave() }, isAddNewSpeciality ? (React.createElement(IconPlus, { className: isDeactivateButton() ? '' : '!text-primary' })) : (React.createElement(IconOkay, { className: isDeactivateButton() ? '' : '!text-primary' })))) : (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { sx: { p: '6px' }, "data-tooltip-content": '\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C', "data-tooltip-id": 'SettingsSpecialityEdit', "aria-label": '\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C', onClick: () => setIsEdit(true) },
            React.createElement(IconPen, null)),
        React.createElement(IconButton, { sx: { p: '6px' }, "data-tooltip-content": '\u0423\u0434\u0430\u043B\u0438\u0442\u044C', "data-tooltip-id": 'SettingsSpecialityDelete', "aria-label": '\u0423\u0434\u0430\u043B\u0438\u0442\u044C', onClick: () => setIsShowDeleteModal(true) },
            React.createElement(IconBasket, null)))));
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalsLayout, { isOpen: isShowDeleteModal, onClose: closeDeleteModal, title: `Вы действительно хотите удалить специальность: ${speciality.name}?`, titleClassName: 'text-center mb-4 text-2xl' },
            React.createElement("p", { className: 'text-center text-lg leading-5' }, "\u042D\u0442\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043D\u0435\u043B\u044C\u0437\u044F \u0431\u0443\u0434\u0435\u0442 \u043E\u0442\u043C\u0435\u043D\u0438\u0442\u044C"),
            React.createElement(Stack, { direction: 'row', sx: { mt: '32px', gap: '16px' } },
                React.createElement(ButtonsMain, { className: 'w-[150px]', onClick: closeDeleteModal }, "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C"),
                React.createElement(ButtonsText, { className: 'w-[150px] border-red border border-solid bg-white text-red rounded-[48px] hover:text-red', onClick: () => onDelete(speciality.id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))),
        React.createElement(ClickAwayListener, { onClickAway: () => setIsEdit(false) },
            React.createElement("tr", { className: 'text-gray-75 text-smaller', style: { background: isEditOrAddNew ? palette.blue[50] : '' } },
                React.createElement("td", { className: 'pl-6 w-[70%]', "aria-label": '\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438' },
                    React.createElement("input", { style: {
                            width: '100%',
                            lineHeight: '18px',
                            fontWeight: 600,
                            fontSize: '14px',
                            background: 'transparent',
                            outline: 'none'
                        }, type: 'text', ref: ref, placeholder: isEditOrAddNew ? 'Введите название специальности' : '', disabled: !isEditOrAddNew, value: inputValue, onChange: e => setInputValue(e.target.value) }),
                    isEditOrAddNew && (React.createElement("div", { className: 'font-normal text-xs' }, isDuplicate ? (React.createElement("span", { className: 'text-red' }, "\u042D\u0442\u0430 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0443\u0436\u0435 \u0431\u044B\u043B\u0430 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0430. \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043B\u0438\u0431\u043E \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u043D\u043E\u0432\u0443\u044E, \u043B\u0438\u0431\u043E \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u044E\u0449\u0443\u044E")) : (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: 'text-primary' }, inputValue.length),
                        React.createElement("span", { className: 'text-gray-main' },
                            "/",
                            maxLength)))))),
                React.createElement("td", { className: 'w-1/5 text-center' }, countSpecialities),
                React.createElement("td", { className: 'w-[10%] text-center', "aria-label": '\u041A\u043D\u043E\u043F\u043A\u0438 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0438 \u0443\u0434\u0430\u043B\u0435\u043D\u0438\u044F' }, isLoading ? React.createElement(CircularProgress, { size: 30 }) : renderButtons())))));
};
