import { create } from 'apisauce';
export const socketUrl = '/cable';
// Получение мета тега с CSRF токеном
const csrfMetaTag = document?.querySelector("meta[name='csrf-token']");
// Настройки заголовков и базового url
export const apiWrapper = create({
    baseURL: '/api/v1/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'token-type': 'Bearer',
        'X-CSRF-Token': csrfMetaTag?.content
    }
});
export const apiUrls = {
    accounts: {
        repetitionPeriods: {
            list: (accountId) => `accounts/${accountId}/show_repetition_periods`,
            update: (accountId) => `accounts/${accountId}/change_repetition_period`
        }
    },
    appConfig: {
        releaseVersion: 'release_version',
        show: 'app_config'
    },
    auth: {
        signIn: '/auth/sign_in',
        confirmationCode: '/auth/send_confirmation_code'
    },
    campaigns: {
        list: '/campaigns',
        replyTemplates: {
            show: (campaignId, templateId) => `/campaigns/${campaignId}/reply_templates/${templateId}`
        },
        show: (id) => `/campaigns/${id}`,
        statistic: (campaignId) => `/campaigns/${campaignId}/statistic`,
        statisticByMonth: (campaignId) => `/campaigns/${campaignId}/statistic_by_month`,
        switchActive: (id) => `/campaigns/${id}/switch_active`,
        triggers: 'campaigns/triggers',
        messages: {
            list: (id) => `/campaigns/${id}/conversation_messages`,
            show: (campaignId, messageId) => `/campaigns/${campaignId}/conversation_messages/${messageId}`
        }
    },
    campaignClients: {
        changeStatus: 'campaign_clients/change_status',
        clientsAnswers: 'campaign_clients/clients_answers',
        index: 'campaign_clients'
    },
    chats: {
        list: 'chats/items',
        operators: { list: 'chats/items/operators' },
        archive: { list: 'chats/items/archive' },
        search: 'chats/items/search',
        item: {
            /**
             * Назначение оператора для чата
             * @param chatId id чата
             */
            assign: (chatId) => `chats/items/${chatId}/assign`,
            /**
             * Добавление чата в архив
             * @param chatId id чата
             */
            toArchive: (chatId) => `chats/items/${chatId}/to_archive`,
            /**
             * Возвращение чата из архива
             * @param chatId id чата
             */
            fromArchive: (chatId) => `chats/items/${chatId}/from_archive`,
            /**
             * Получение чата
             * @param chatId id чата
             */
            show: (chatId) => `chats/items/${chatId}`
        },
        messages: {
            /**
             * Получение сообщений или создание сообщения(POST)
             * @param chatId id чата
             */
            list: (chatId) => `chats/items/${chatId}/messages`,
            /**
             * Сделать сообщение прочитанным
             * @param chatId id чата
             */
            markRead: (chatId) => `chats/items/${chatId}/messages/mark_as_read`,
            unreadCount: 'chats/items/unread_messages_count'
        }
    },
    clients: {
        show: (id) => `/clients/${id}`,
        treats: (id) => `/clients/${id}/treats_list`,
        changeNotificationAllowance: (id) => `/clients/${id}/change_notification_allowance`,
        /**
         * Изменить доступ к мобильному приложению
         * @param id Id клиента
         */
        changeMobileAccess: (id) => `/clients/${id}/change_mobile_access`,
        conversationsList: (id) => `/clients/${id}/conversations/list_by_client`
    },
    conversations: { show: (id) => `/conversations/${id}/conversation_messages/list_by_conversation` },
    doctors: {
        changeServiceCategory: 'doctors/change_service_category',
        list: 'doctors',
        // Список врачей всего
        listWithoutCategory: 'doctors/list_without_service_category',
        /**
         * @param id - id врача
         * @returns адрес для получения данных врача
         */
        show: (id) => `/doctors/${id}`,
        specialities: { list: '/doctors/specialities' }
    },
    serviceCategories: {
        changeServiceCategory: (categoryId) => (`service_categories/${categoryId}/services/change_service_category`),
        // Список врачей в категории
        doctors: (categoryId) => `service_categories/${categoryId}/show_doctors_in_category`,
        list: '/service_categories',
        // Список сервисов в категории
        services: (categoryId) => `service_categories/${categoryId}/show_services_in_category`,
        show: (categoryId) => `service_categories/${categoryId}`,
        // Список сервисов всего
        showTreat: 'service_categories/show_treat'
    },
    schedules: {
        changeStatus: '/schedules/change_status',
        list: '/schedules'
    },
    services: {
        // Список услуг вне этапа сценария лечения
        notInStage: '/services/services_not_in_treat_scenario_stage'
    },
    templates: {
        list: '/templates',
        show: (id) => `/templates/${id}`
    },
    treatScenarios: {
        services: {
            // Список услуг в этапе сценария лечения
            list: (scenarioId, stageId) => `/treat_scenarios/${scenarioId}/treat_scenario_stages/${stageId}`,
            changeService: (scenarioId, stageId) => `/treat_scenarios/${scenarioId}/treat_scenario_stages/${stageId}/change_service`
        },
        list: '/treat_scenarios',
        show: (id) => `/treat_scenarios/${id}`,
        clients: {
            changeStatus: '/treat_scenario_clients/change_status',
            list: '/treat_scenario_clients'
        }
    },
    users: { list: { byUserIntegrations: 'users/list_by_user_integrations' } },
    specialities: {
        list: '/specialities',
        /**
         * Назначить специальность
         * @param id - идентификатор специальности
         */
        assignSpeciality: (id) => `/specialities/${id}/assign`,
        /**
         * Обновить специальность
         * @param id - идентификатор специальности
         */
        updateSpeciality: (id) => `/specialities/${id}`
    }
};
