import { MuiTabs } from 'helpers/MuiTabs/MuiTabs';
import { Box } from '@mui/material';
import React from 'react';
import { useQueryParam } from 'use-query-params';
import { SettingsSpecialityAdd } from './Add';
import { SettingsSpecialityAppointment } from './Appointment';
const tabsKeys = ['add', 'appointment'];
const tabs = {
    add: {
        title: 'Добавление',
        /**
         * Компонент для таба "Добавление"
         */
        component: React.createElement(SettingsSpecialityAdd, null)
    },
    appointment: {
        title: 'Назначение',
        /**
         * Компонент для таба "Назначение"
         */
        component: React.createElement(SettingsSpecialityAppointment, null)
    }
};
/**
 * Компонент настроек специальности
 */
const SettingsSpeciality = () => {
    const [tabKey, setTabKey] = useQueryParam('second_tab');
    const safeActiveTab = tabKey || tabsKeys[0];
    /**
     * Рендер контента
     */
    const renderContent = () => (React.createElement(Box, { sx: { p: '32px' } },
        React.createElement(MuiTabs, { activeTab: safeActiveTab, setActiveTab: setTabKey, tabs: tabs })));
    return renderContent();
};
export default SettingsSpeciality;
