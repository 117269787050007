import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiUrls } from 'config/api';
import { addSpeciality } from './addSpeciality';
/**
 * Мутация для добавления специальности
 */
export const useAddSpecialityMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        /**
         * Мутация для добавления специальности
         * @param body - тело запроса
         */
        mutationFn: addSpeciality,
        /**
         * onSuccess вызывается после успешной мутации добавления специальности
         */
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [apiUrls.specialities.list] }); // Инвалидируем список специальностей
        }
    });
};
