import React from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Stack, Tab, Tabs, useTheme } from '@mui/material';
/**
 * Компонента отрисовки табов с опциональным контентом внутри
 * @param root0 - пропсы
 * @param root0.tabs - табы
 * @param root0.activeTab - активный таб
 * @param root0.setActiveTab - обработка переключения таба
 */
export const MuiTabs = ({ tabs, activeTab, setActiveTab }) => {
    const { palette } = useTheme();
    const [, setPage] = useQueryParam('page', NumberParam);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { width: '100%' },
            React.createElement(Tabs, { value: activeTab, variant: 'fullWidth', onChange: (_, value) => {
                    setActiveTab(value);
                    setPage(0);
                }, sx: { borderRadius: '8px', '& .MuiTabs-indicator': { display: 'none' } } }, Object.entries(tabs).map(([key, tab]) => (React.createElement(Tab, { label: tab.title, key: key, value: key, sx: {
                    fontSize: '18px',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    lineHeight: '21px',
                    minHeight: '45px',
                    color: palette.custom.grey[500],
                    background: palette.common.white,
                    '&.Mui-selected': { color: palette.blue[700], background: palette.blue[200] },
                    '& .MuiTouchRipple-root': { display: 'none' }
                } }))))),
        tabs[activeTab]?.component && (React.createElement(Stack, { py: 6, width: '100%' }, tabs[activeTab]?.component))));
};
