export const colorSchemes = {
    light: {
        palette: {
            primary: { main: '#3E9AF5' },
            blue: {
                50: '#ECF5FE', 100: '#DDEEFF', 200: '#C6EBEC', 300: '#2AB7EE', 400: '#306796', 700: '#4E5771'
            },
            green: { 500: '#00940F' },
            grey: {
                50: '#F3F5F7',
                100: '#EAEEF1',
                200: '#D6DFE9',
                300: '#CAD1D8',
                400: '#A6ABAF',
                500: '#7D8082',
                600: '#6D7986',
                700: '#484E54',
                800: '#3A3E41',
                900: '#212426'
            },
            red: { 500: '#DE0909' },
            yellow: { 50: '#FFF4E3' },
            success: { main: '#00BD13' },
            error: { main: '#CB1C1C' },
            warning: {
                light: '#FFF0D4',
                main: '#FFB830'
            },
            info: {
                light: '#E2F7FF',
                main: '#D6F4FF',
                dark: '#183745'
            },
            custom: { grey: { 500: '#A3ABAD', 600: '#A3ABB1' }, shadows: ['0px 4px 4px 0px #D6D6D640'] }
        }
    }
};
