import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useChat } from 'actions/chats/useChat';
import { ChatsMessengerInput } from './Input';
import { ChatsMessengerMessages } from './messages';
/**
 * Мессенджер чатов
 * @param root0 - пропсы
 * @param root0.chatId - id чата
 */
export const ChatsMessenger = ({ chatId }) => {
    const scrollRef = useRef(null);
    const scrollPosition = useRef(0);
    const [isScrolledOnMount, setIsScrolledOnMount] = useState(false);
    const [isPageIncreased, setIsPageIncreased] = useState(false);
    // Перевод скролла в нижнюю позицию
    const scrollToBottom = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            return true;
        }
        return false;
    }, []);
    // Обработка скролла при получении сообщений
    /**
     *
     */
    const onIncomingMessage = () => {
        const scroll = scrollRef.current;
        if (scroll) {
            const scrollBottom = scroll.scrollHeight - scroll.scrollTop;
            // Скролл к низу, если позиция скрола рядом с нижний позицией
            if (scrollBottom < (scroll.clientHeight * 2))
                scrollToBottom();
        }
    };
    const { messages, sendMessage, isLoading, isAddLoading, message, setMessage, files, setFiles, isMessageSending, increasePage, voiceMessageFile, setVoiceMessageFile } = useChat(chatId, {
        onOutgoingMessage: scrollToBottom,
        onIncomingMessage
    });
    useLayoutEffect(() => {
        setIsScrolledOnMount(false);
    }, [chatId]);
    // Перевод скролла в нижнюю позицию при начальном рендере
    useLayoutEffect(() => {
        if (!isScrolledOnMount && !!messages.length) {
            const isScrolled = scrollToBottom();
            if (isScrolled) {
                setIsScrolledOnMount(true);
            }
        }
    }, [scrollToBottom, messages]);
    // Оставляем скролл на текущей позиции после получении новой страницы
    useLayoutEffect(() => {
        const scroll = scrollRef.current;
        if (isPageIncreased && scroll) {
            const newScrollHeight = scroll.scrollHeight;
            if (newScrollHeight > scrollPosition.current) {
                scroll.scrollTop = newScrollHeight - scrollPosition.current;
                setIsPageIncreased(false);
            }
        }
    }, [isPageIncreased, messages]);
    /**
     * Увеличение страницы при приближении скрлла к верхней позиции
     * @param event - Событие
     */
    const handleScroll = (event) => {
        const { scrollTop } = event.target;
        const threshold = 20;
        if (scrollTop <= threshold) {
            increasePage();
            scrollPosition.current = scrollRef.current?.scrollHeight || 0;
            setIsPageIncreased(true);
        }
    };
    /**
     * Основной контент
     */
    const renderContent = () => (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden'
        } },
        React.createElement(ChatsMessengerMessages, { messages: messages, isLoading: isLoading, isAddLoading: isAddLoading, handleScroll: handleScroll, ref: scrollRef }),
        React.createElement(ChatsMessengerInput, { message: message, setMessage: setMessage, onSend: sendMessage, files: files, voiceMessageFile: voiceMessageFile, setVoiceMessageFile: setVoiceMessageFile, setFiles: setFiles, isDisabled: isMessageSending || isLoading })));
    return renderContent();
};
