import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAppSelector } from 'redux/hooks';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ChatsContext } from 'components/chats/Context';
import { getChatsListUrl } from 'functions/Chats';
import { useAvailableSections } from 'functions/data';
import { useChannel } from 'functions/Websocket';
import { apiUrls } from 'config/api';
import notificationSound from 'sounds/notification.mp3';
import { getChats } from './getChats';
import { getUnreadMessagesCount } from './getUnreadMessagesCount';
/**
 * Хук для получения данных для чатов
 */
export const useChats = () => {
    const [activeTab] = useQueryParam('tab', StringParam);
    const [, setChatId] = useQueryParam('chat_id', StringParam);
    const { setSelectedChatUnreadCount } = useContext(ChatsContext);
    const { auth: authData, data: userData } = useAppSelector(state => state.user);
    const [chats, setChats] = useState([]);
    const [totalUnreadCount, setTotalUnreadCount] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const availableSections = useAvailableSections();
    const [chatsListUrl, setChatsListUrl] = useState(null);
    const { data, error, isFetching } = useQuery({
        queryKey: [chatsListUrl, page],
        queryFn: getChats,
        enabled: !!chatsListUrl,
        refetchOnWindowFocus: false
    });
    const { data: unreadMessagesData, error: unreadMessagesError } = useQuery({
        queryKey: [apiUrls.chats.messages.unreadCount],
        queryFn: getUnreadMessagesCount,
        enabled: availableSections.includes('chats'),
        refetchOnWindowFocus: false
    });
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Установка нового значения общего количества непрочитанных сообщений в стейт
     * @param count - общее количество непрочитанных сообщений
     */
    const setUnreadCountAllToState = (count) => {
        if (count || count === 0) {
            setTotalUnreadCount(count);
        }
    };
    useEffect(() => {
        setPage(1);
        setChatsListUrl(getChatsListUrl(activeTab));
    }, [activeTab]);
    useEffect(() => {
        if (data) {
            if (data.pagination?.pages)
                setTotalPages(data.pagination.pages);
            const isFirstPage = page === 1;
            setChats(prev => (isFirstPage ? data.chats : [...prev, ...data.chats]));
        }
    }, [data]);
    useEffect(() => {
        if (unreadMessagesData?.messages_unread_count || unreadMessagesData?.messages_unread_count === 0) {
            setTotalUnreadCount(unreadMessagesData.messages_unread_count);
        }
    }, [unreadMessagesData]);
    useEffect(() => {
        const responseError = unreadMessagesError || error;
        if (responseError) {
            enqueueSnackbar(responseError.message, { variant: 'error' });
        }
    }, [error]);
    /**
     * Обработка получения сообщения из сокета
     * @param chanelData - данные канала
     */
    const chatsChannelReceiveAction = (chanelData) => {
        const { chat_data: chatData, unread_count_all: unreadCountAll, readed_chat_id: readedChatId } = chanelData;
        setUnreadCountAllToState(unreadCountAll);
        if (chatData?.last_message_authorable_id !== userData.id && !chatData?.last_message_automatic) {
            new Audio(notificationSound).play();
        }
        if (readedChatId) {
            setChats(prev => prev.map((chat) => {
                if (chat.id === readedChatId)
                    return { ...chat, unread_count: 0 };
                return chat;
            }));
        }
        if (chatData) {
            if (chatData.unread_count > 0 && !chatData.last_message_automatic) {
                // Обновляем массив чатов новыми данными
                setChats((prev) => {
                    const updatedChat = prev.find(chat => chat.id === chatData.id);
                    if (updatedChat) {
                        const updatedChats = prev.filter(chat => chat.id !== updatedChat.id);
                        return [{ ...updatedChat, ...chatData }, ...updatedChats];
                    }
                    return prev;
                });
            }
            else {
                setChats(prev => prev.map((chat) => {
                    if (chat.id === chatData.id)
                        return { ...chatData, unread_count: chatData.unread_count };
                    return chat;
                }));
            }
            // Если в текущем открытом чате появились непрочитанные сообщений - заносим их число в контекст
            // setChatId используется для получения актуального id
            setChatId((currentChatId) => {
                if (currentChatId === chatData.id) {
                    setSelectedChatUnreadCount(chatData.unread_count);
                }
                return currentChatId;
            });
        }
    };
    const { handleJoinChannel, isChannelConnectionFailed } = useChannel({
        channel: 'Chats::UserChannel',
        token: authData?.['access-token'],
        client: authData?.client,
        id: userData.id
    }, chatsChannelReceiveAction);
    // Подключение к каналу
    useLayoutEffect(() => {
        const channel = handleJoinChannel();
        return () => {
            channel?.unsubscribe();
        };
    }, []);
    // Увеличение страницы
    const increasePage = useCallback(() => {
        if (totalPages > page && page === data?.pagination.page)
            setPage((prev) => prev + 1);
    }, [totalPages, page, data]);
    // Обработка ошибки подключения к каналу
    useEffect(() => {
        if (isChannelConnectionFailed) {
            enqueueSnackbar('Ошибка подключения к чатам', { variant: 'error' });
        }
    }, [isChannelConnectionFailed]);
    const memoizedValue = useMemo(() => ({
        chats,
        isLoading: isFetching && !chats.length,
        isLoadingAddItems: isFetching && !!chats.length,
        unreadMessagesCount: totalUnreadCount,
        increasePage
    }), [
        chats, isFetching, totalUnreadCount, increasePage
    ]);
    return memoizedValue;
};
