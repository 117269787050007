import React, { useRef, useState } from 'react';
import { alpha, Box, CircularProgress, IconButton, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useWavesurfer } from '@wavesurfer/react';
/**
 * Компонент воспроизвденения аудио
 * @param root0 - пропсы
 * @param root0.file - аудио файл
 * @param root0.isMyMessage - мое ли сообщение
 */
export const ChatsMessengerAudioPlayer = ({ file, isMyMessage }) => {
    const { palette } = useTheme();
    const containerRef = useRef(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const speeds = [0.25, 0.5, 1, 2, 4];
    const { wavesurfer, isPlaying, currentTime: wsCurrentTime, isReady } = useWavesurfer({
        container: containerRef,
        waveColor: palette.primary.main,
        progressColor: palette.primary.main,
        barWidth: 2,
        barHeight: 1,
        height: 24,
        width: 230,
        barRadius: 10,
        barGap: 2,
        audioRate: 1,
        url: file.url_original
    });
    const currentTime = isReady ? moment.utc(wsCurrentTime * 1000).format('mm:ss') : '00:00';
    const totalTime = isReady && wavesurfer ? moment.utc(wavesurfer.getDuration() * 1000).format('mm:ss') : '00:00';
    /**
     * Изменение скорости воспроизведения аудио
     */
    const changePlaybackSpeed = () => {
        const currentIndex = speeds.indexOf(playbackSpeed);
        const nextIndex = (currentIndex + 1) % speeds.length;
        const newSpeed = speeds[nextIndex];
        setPlaybackSpeed(newSpeed);
        // Обновление скорости
        wavesurfer?.setPlaybackRate(newSpeed);
    };
    /**
     * Отрисовка кнопки воспроизведения
     */
    const renderPlayButton = () => (React.createElement(IconButton, { onClick: () => wavesurfer?.playPause(), sx: {
            display: isReady ? 'flex' : 'none',
            height: 32,
            width: 32,
            bgcolor: isMyMessage ? palette.common.white : palette.primary.main,
            borderRadius: '50%',
            mr: 3,
            '&:hover': { bgcolor: isMyMessage ? alpha(palette.common.white, 0.8) : alpha(palette.primary.main, 0.8) }
        } }, isPlaying ? (React.createElement(PauseRoundedIcon, { sx: { color: isMyMessage ? palette.primary.main : palette.common.white } })) : (React.createElement(PlayArrowRoundedIcon, { sx: { color: isMyMessage ? palette.primary.main : palette.common.white } }))));
    /**
     * Отрисовка кнопки изменения скорости воспроизведения аудио
     */
    const renderPlaybackSpeedButton = () => (React.createElement(IconButton, { sx: {
            bgcolor: palette.common.white,
            borderRadius: '12px',
            ml: 2,
            alignSelf: 'start',
            p: 0,
            display: isReady ? 'flex' : 'none',
            width: 42,
            '&:hover': { bgcolor: alpha(palette.common.white, 0.8) }
        }, onClick: changePlaybackSpeed },
        React.createElement(Typography, { fontWeight: 500, variant: 'body1', color: palette.blue[700] },
            playbackSpeed,
            " x")));
    const renderLoader = (React.createElement(Box, { sx: {
            width: '100%', maxWidth: '345px', display: 'flex', justifyContent: 'center', alignItems: 'center'
        } },
        React.createElement(CircularProgress, { size: 24 })));
    return (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', mb: 2 } },
        !isReady && renderLoader,
        renderPlayButton(),
        React.createElement(Stack, { gap: 0.5 },
            React.createElement(Box, { ref: containerRef, sx: { width: '100%', maxWidth: '345px' } }),
            React.createElement(Typography, { variant: 'body2', sx: { display: isReady ? 'block' : 'none' }, color: palette.primary.main },
                currentTime,
                "/",
                totalTime)),
        renderPlaybackSpeedButton()));
};
