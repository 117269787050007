import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apiUrls } from 'config/api';
import { getDoctorsSpecialities } from './getDoctorsSpecialities';
/**
 * Хук для получения специальностей из МИС
 */
export const useDoctorSpecialities = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: dataDoctorsSpecialities, error: errorDoctorsSpecialities, isFetching: isFetchingDoctorsSpecialities } = useQuery({
        queryKey: [
            apiUrls.doctors.specialities.list
        ],
        queryFn: getDoctorsSpecialities,
        refetchOnWindowFocus: false
    });
    useEffect(() => {
        if (errorDoctorsSpecialities) {
            enqueueSnackbar(errorDoctorsSpecialities.message, { variant: 'error' });
        }
    }, [errorDoctorsSpecialities]);
    return {
        dataDoctorsSpecialities,
        isFetchingDoctorsSpecialities
    };
};
