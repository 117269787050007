import React from 'react';
import { alpha, Box, Icon, Link, Stack, Typography, useTheme } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment-timezone';
import { useAppSelector } from 'redux/hooks';
import { useChatItem } from 'actions/chats/useChatItem';
import { IconDocument } from 'svg/chats';
import { formatBytes } from 'functions/Files';
import { ChatsMessengerAudioPlayer } from '../../audio/Player';
const validAudioFormats = [
    'audio/mpeg',
    'audio/ogg',
    'audio/webm',
    'audio/opus'
];
/**
 * Получить отображаемое имя
 * @param isMyMessage - мое ли сообщение
 * @param user - пользователь
 * @param chat - имя чата
 */
const getDisplayName = (isMyMessage, user, chat) => {
    if (isMyMessage) {
        return 'ВЫ';
    }
    if (user.type === 'User') {
        return user.first_name?.substring(0, 1);
    }
    if (chat) {
        return chat.chat_name?.substring(0, 2);
    }
    return '';
};
/**
 * Элемент сообщения
 * @param root0 - пропсы
 * @param root0.message - сообщение
 */
export const ChatsMessengerMessagesItem = ({ message }) => {
    const { data: chat } = useChatItem(message.item_id);
    const { data: userData } = useAppSelector(state => state.user);
    const { user, text, created_at: createdAt } = message;
    const isMyMessage = user.id === userData.id;
    const { palette } = useTheme();
    /**
     * Отрисовка блока отправителя сообщения
     */
    const renderSenderBlock = () => (React.createElement(Stack, { alignItems: 'center', justifyContent: 'center', height: 40, order: 0, bgcolor: isMyMessage ? palette.green[500] : palette.red[500], minWidth: 40, borderRadius: '50%' },
        React.createElement(Typography, { variant: 'subtitle2', color: palette.common.white, fontWeight: 700 }, getDisplayName(isMyMessage, user, chat))));
    /**
     * @param file - файл
     * @returns возвращает элемент, отображающий файл для скачивания
     */
    const renderFile = (file) => (React.createElement(Link, { key: file.filename, sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: '8px',
            background: isMyMessage ? alpha(palette.common.white, 0.5) : alpha(palette.grey[100], 0.3),
            borderRadius: '8px',
            height: 64,
            width: '345px',
            p: 3,
            textDecoration: 'none',
            '&:hover': {
                background: isMyMessage ? alpha(palette.common.white, 0.4) : alpha(palette.grey[100], 0.2),
                textDecoration: 'none'
            }
        }, href: file.url_original, download: true },
        React.createElement(Stack, { alignItems: 'center', justifyContent: 'center', minWidth: 40, borderRadius: '50%', sx: { background: 'linear-gradient(90deg, #13c1bc 0%, #3dd6d4 100%)' }, height: 40 },
            React.createElement(Icon, { sx: { width: '24px', height: '24px' } },
                React.createElement(IconDocument, null))),
        React.createElement(Box, { sx: { width: 232, gap: 0.5 } },
            React.createElement(Typography, { variant: 'subtitle2', fontWeight: 400, noWrap: true, textOverflow: 'ellipsis', color: palette.grey[900] }, file.filename),
            React.createElement(Typography, { variant: 'body2', fontWeight: 500, color: palette.grey[500] }, formatBytes(file.byte_size))),
        React.createElement(FileDownloadOutlinedIcon, { sx: { width: '32px', height: '32px', color: palette.primary.main } })));
    /**
     * @param file - файл
     * @returns элемент картинки
     */
    const renderImage = (file) => (React.createElement(Box, { key: file.filename },
        React.createElement("img", { src: file.url_original, alt: file.filename, style: { borderRadius: '4px', maxWidth: '345px' } })));
    /**
     * @param file - файл
     * @returns - в зависимости от типа файла вызывает отрисовку картинки или файла
     */
    const renderFileOrImage = (file) => {
        if (file.content_type === 'application/pdf') {
            return renderFile(file);
        }
        if (validAudioFormats.includes(file.content_type)) {
            return React.createElement(ChatsMessengerAudioPlayer, { key: file.id, file: file, isMyMessage: isMyMessage });
        }
        return renderImage(file);
    };
    return (React.createElement(Stack, { direction: 'row', gap: 3, justifyContent: isMyMessage ? 'flex-end' : 'unset' },
        React.createElement(Stack, { alignItems: isMyMessage ? 'flex-end' : 'flex-start', order: isMyMessage ? 0 : 1, bgcolor: isMyMessage ? palette.blue[100] : palette.common.white, sx: {
                py: 2.5,
                px: 3,
                gap: 2,
                borderRadius: 1.5,
                boxShadow: '0 4px 4px 0 rgba(180, 180, 180, 0.25)'
            } },
            React.createElement(Typography, { variant: 'subtitle2', fontWeight: 400, color: palette.grey[900] },
                (user.type === 'User' && text && user.first_name) && `${user.first_name}: `,
                text),
            message.files.length > 0 && (React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                } }, message.files.map(file => (renderFileOrImage(file))))),
            React.createElement(Stack, { direction: 'row', gap: 1 },
                React.createElement(Typography, { variant: 'body2', color: palette.grey[600] }, moment(createdAt).format('HH:mm')))),
        renderSenderBlock()));
};
