import { closeSnackbar, enqueueSnackbar } from 'notistack';
/**
 * Хук для вызова кастомных уведомлений notistack
 */
export const useCustomAlerts = () => {
    /**
     * Ф-ия вызывает уведомление
     * @param text - тест уведомления
     * @param styles - кастомные стили
     */
    const showCustomAlert = (text, styles) => {
        enqueueSnackbar(text, {
            variant: 'customAlert',
            /**
             * ф-ия закрытия notistack
             * @param id - id
             */
            onClose: id => closeSnackbar(id),
            SnackbarProps: {
                style: {
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'end',
                    position: 'fixed',
                    height: 'fit-content',
                    top: '20px',
                    paddingLeft: '32px',
                    paddingRight: '43px'
                }
            },
            ...{
                icon: styles?.icon,
                className: styles?.className,
                severity: styles?.severity
            }
        });
    };
    return { showCustomAlert };
};
