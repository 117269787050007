import { apiUrls, apiWrapper } from 'config/api';
import { handleErrors } from 'functions/Errors';
/**
 * Обновление специальности
 * @param root0 - пропсы
 * @param root0.id - Идентификатор специальности
 */
export const deleteSpeciality = async ({ id }) => {
    const response = await apiWrapper.delete(apiUrls.specialities.updateSpeciality(id));
    if (!response.ok) {
        const error = handleErrors(response);
        throw new Error(error);
    }
    return response.data || {};
};
