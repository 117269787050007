import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiUrls } from 'config/api';
import { deleteSpeciality } from './deleteSpeciality';
/**
 * Мутация для удаления специальности
 */
export const useDeleteSpecialityMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        /**
         * Мутация для удаления специальности
         */
        mutationFn: deleteSpeciality,
        /**
         * onSuccess вызывается после успешного удаления мутации
         */
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [apiUrls.specialities.list] });
            // Инвалидируем список специальностей после удаления
        }
    });
};
