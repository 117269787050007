import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apiUrls } from 'config/api';
import { getSpecialities } from './getSpecialities';
/**
 * Хук для получения специальностей из MetaClinic
 */
export const useSpecialities = () => {
    const { data: dataSpecialities, error: errorSpecialities, isFetching: isFetchingSpecialities } = useQuery({
        queryKey: [apiUrls.specialities.list],
        queryFn: getSpecialities,
        refetchOnWindowFocus: false
    });
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (errorSpecialities) {
            enqueueSnackbar(errorSpecialities.message, { variant: 'error' });
        }
    }, [errorSpecialities]);
    return {
        dataSpecialities,
        isFetchingSpecialities
    };
};
