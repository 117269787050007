import React, { useEffect, useRef, useState } from 'react';
import { alpha, Box, ClickAwayListener, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import moment from 'moment-timezone';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import SendIcon from '@mui/icons-material/Send';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { useSnackbar } from 'notistack';
import WaveSurfer from 'wavesurfer.js';
import { IconDelete } from 'svg/chats';
import { ChatsMessengerAudioControlButton } from './ControlButton';
import { ChatsMessengerAudioRecordingIndicator } from './RecordingIndicator';
/**
 * Компонент модального окна с записью голосового сообщения
 * @param root0 - пропсы
 * @param root0.onClose - функция вызываемая при закрытии модалки
 * @param root0.onSend - функция для отправки готовой записи на сервер
 * @param root0.voiceMessageFile - файл голосового сообщения
 * @param root0.setVoiceMessageFile - функция для изменения файла голосового сообщения
 */
export const ChatsMessengerAudioRecorder = ({ onClose, onSend, voiceMessageFile, setVoiceMessageFile }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { palette } = useTheme();
    const micContainerRef = useRef(null);
    const recordingsContainerRef = useRef(null);
    const [wavesurfer, setWavesurfer] = useState(null);
    const [recordPlugin, setRecordPlugin] = useState(null);
    const [recordingProgress, setRecordingProgress] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [recordedWaveSurfer, setRecordedWaveSurfer] = useState(null);
    const [audioDevices, setAudioDevices] = useState([]);
    const [recordingTimer, setRecordingTimer] = useState(null);
    const [isRecordedAudioPlaying, setIsRecordedAudioPlaying] = useState(false);
    const [recordedAudioCurrentTime, setRecordedAudioCurrentTime] = useState('00:00');
    const [recordedAudioTotalTime, setRecordedAudioTotalTime] = useState('00:00');
    /**
     * Создание экземпляра WaveSurfer
     */
    const createWaveSurfer = () => {
        // Уничтожаем предыдущий экземпляр
        if (wavesurfer) {
            wavesurfer.destroy();
        }
        if (recordedWaveSurfer) {
            recordedWaveSurfer.destroy();
        }
        // Создаем новый экземпляр WaveSurfer
        const newWaveSurfer = WaveSurfer.create({
            container: micContainerRef.current,
            waveColor: palette.primary.main,
            progressColor: palette.primary.main,
            barWidth: 2,
            barHeight: 1,
            height: 24,
            barRadius: 10,
            barGap: 2
        });
        // Инициализация Record плагина
        const record = RecordPlugin.create({ renderRecordedAudio: false });
        newWaveSurfer.registerPlugin(record);
        record.on('record-end', (blob) => {
            setVoiceMessageFile(blob);
            handleRecordedBlob(blob);
        });
        record.on('record-progress', (time) => {
            const formattedTime = moment.utc(time).format('HH:mm:ss');
            setRecordingProgress(formattedTime);
        });
        setWavesurfer(newWaveSurfer);
        setRecordPlugin(record);
    };
    /**
     * Обработка записанного аудио
     * @param blob - файл записи
     */
    const handleRecordedBlob = (blob) => {
        const recordedUrl = URL.createObjectURL(blob);
        // Clear previous recording
        if (recordedWaveSurfer)
            recordedWaveSurfer.destroy();
        if (recordingsContainerRef.current)
            recordingsContainerRef.current.innerHTML = '';
        // Создаем новый WaveSurfer для записи
        const recordedWaveSurferInstance = WaveSurfer.create({
            container: recordingsContainerRef.current,
            waveColor: palette.primary.main,
            progressColor: palette.primary.main,
            barWidth: 2,
            barHeight: 1,
            height: 24,
            barRadius: 10,
            barGap: 2,
            url: recordedUrl
        });
        recordedWaveSurferInstance.on('ready', () => {
            setRecordedAudioCurrentTime('00:00');
            setRecordedAudioTotalTime(moment.utc(recordedWaveSurferInstance.getDuration() * 1000).format('mm:ss'));
        });
        recordedWaveSurferInstance.on('audioprocess', () => {
            setRecordedAudioCurrentTime(moment.utc(recordedWaveSurferInstance.getCurrentTime() * 1000).format('mm:ss'));
        });
        recordedWaveSurferInstance.on('pause', () => setIsRecordedAudioPlaying(false));
        recordedWaveSurferInstance.on('play', () => setIsRecordedAudioPlaying(true));
        setRecordedWaveSurfer(recordedWaveSurferInstance);
    };
    /**
     * Инициализация микрофонов
     */
    const initAudioDevices = async () => {
        const devices = await RecordPlugin.getAvailableAudioDevices();
        setAudioDevices(devices);
    };
    // Обновляем WaveSurfer при изменении параметров
    useEffect(() => {
        createWaveSurfer();
        initAudioDevices();
        return () => {
            wavesurfer?.destroy();
            recordedWaveSurfer?.destroy();
            setVoiceMessageFile(null);
            if (recordingTimer)
                clearTimeout(recordingTimer);
        };
    }, []);
    /**
     * Управление началом записи
     * @param deviceId - id устройства
     */
    const handleRecord = async (deviceId) => {
        if (isRecording || isPaused) {
            recordPlugin?.stopRecording();
            setIsRecording(false);
            setIsPaused(false);
            if (recordingTimer)
                clearTimeout(recordingTimer);
            return;
        }
        deleteRecording();
        await recordPlugin?.startRecording({ deviceId });
        setIsRecording(true);
        setRecordingTimer(setTimeout(() => {
            recordPlugin?.stopRecording();
            setIsRecording(false);
            enqueueSnackbar('Превышено максимальное время записи, 10 минут', { variant: 'info' });
        }, 600000));
    };
    // TODO: Реализовать паузу записи
    // const handlePause = () => {
    //   if (recordPlugin.isPaused()) {
    //     recordPlugin.resumeRecording();
    //     setIsPaused(false);
    //   } else {
    //     recordPlugin.pauseRecording();
    //     setIsPaused(true);
    //   }
    // };
    /**
     * Удаление записи
     */
    const deleteRecording = () => {
        if (recordedWaveSurfer) {
            recordedWaveSurfer.destroy();
            recordingsContainerRef.current.innerHTML = '';
            setVoiceMessageFile(null);
            setRecordedWaveSurfer(null);
            setRecordingProgress('');
            setIsRecordedAudioPlaying(false);
        }
    };
    return (React.createElement(ClickAwayListener, { onClickAway: onClose },
        React.createElement(Stack, { sx: {
                display: 'flex',
                borderRadius: '16px',
                width: 300,
                maxHeight: 254,
                boxShadow: '0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)',
                bgcolor: palette.common.white,
                border: '1px solid',
                borderColor: palette.grey[50],
                p: 5,
                position: 'absolute',
                left: '20px',
                bottom: '64px',
                zIndex: 1
            } },
            React.createElement(Box, { sx: {
                    display: voiceMessageFile && !isRecording ? 'flex' : 'none',
                    mb: 5,
                    borderRadius: '12px',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: palette.blue[100]
                } },
                React.createElement(IconButton, { sx: {
                        height: 32,
                        width: 32,
                        bgcolor: palette.primary.main,
                        borderRadius: '50%',
                        mr: 3,
                        '&:hover': { bgcolor: alpha(palette.primary.main, 0.8) }
                    }, onClick: () => recordedWaveSurfer?.playPause() }, isRecordedAudioPlaying ?
                    React.createElement(PauseRoundedIcon, { sx: { color: palette.common.white } }) :
                    React.createElement(PlayArrowRoundedIcon, { sx: { color: palette.common.white } })),
                React.createElement(Box, { sx: { width: '100%' }, ref: recordingsContainerRef }),
                React.createElement(Typography, { variant: 'body2', sx: { ml: 2 }, color: palette.blue[700] },
                    recordedAudioCurrentTime,
                    "/",
                    recordedAudioTotalTime)),
            (!voiceMessageFile || isRecording) && (React.createElement(ChatsMessengerAudioRecordingIndicator, { recordingProgress: recordingProgress, isRecording: isRecording })),
            React.createElement(Box, { display: 'flex', alignItems: 'center', alignSelf: 'center', gap: 5 },
                React.createElement(ChatsMessengerAudioControlButton, { isDisabled: !voiceMessageFile, onClick: deleteRecording },
                    React.createElement(Icon, { sx: { width: 24, height: 24 } },
                        React.createElement(IconDelete, null))),
                React.createElement(ChatsMessengerAudioControlButton, { size: 'large', onClick: () => handleRecord(audioDevices[0]?.deviceId || '') }, isRecording ?
                    React.createElement(StopRoundedIcon, { sx: { width: 32, height: 32, color: palette.red[500] } }) :
                    React.createElement(MicRoundedIcon, { sx: { width: 32, height: 32, color: palette.primary.main } })),
                React.createElement(ChatsMessengerAudioControlButton, { isDisabled: !voiceMessageFile, onClick: () => {
                        setRecordingProgress('');
                        onSend();
                    } },
                    React.createElement(SendIcon, { sx: { width: 24, height: 24, color: palette.primary.main } }))),
            React.createElement(Box, { ref: micContainerRef, sx: { display: 'none' } }))));
};
