import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apiUrls } from 'config/api';
import { assignSpeciality } from './assignSpeciality';
/**
 * Мутация для назначения специальности
 */
export const useAssignSpecialityMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        /**
         * Мутация для назначения специальности
         * @param id - Идентификатор специальности
         * @param body - тело запроса
         */
        mutationFn: assignSpeciality,
        /**
         * Отображение ошибки мутации
         * @param error Ошибка
         */
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        /**
         * onSettled вызывается всегда (и при успехе, и при ошибке)
         */
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [apiUrls.doctors.specialities.list] }); // Инвалидируем нужный запрос
        }
    });
};
