import React, { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, IconButton, ListItem, Popper, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SettingsSpecialityButton } from 'components/settings/Speciality/Button';
import { IconDropdown, IconOkay } from 'svg/common';
/**
 * Кастомный внутренний элемент Popper, который отвечает за отображение выпадающего списка
 * @param props - стандартные mui пропсы
 * @returns кастомный выпадающий список
 */
const CustomPopper = (props) => {
    const { sx, ...otherProps } = props;
    return (React.createElement(Popper, { ...otherProps, sx: {
            marginTop: '15px !important',
            '& .MuiPaper-root': {
                borderRadius: '4px',
                /**
                 * Свойство border
                 * @param theme - тема
                 */
                border: theme => `1px solid ${theme.palette.custom.grey[600]}`
            },
            '& #custom-mui-autocomplete-listbox': { py: '4px' },
            ...sx
        } }));
};
/**
 * Компонент для ввода и выбора значений из выкидного списка
 * @param root0 - пропсы
 * @param root0.selectOptions - опции для мульти-селекта
 * @param root0.isDisabled - отключение селекта
 * @param root0.selectedOption - выбранное значение селекта
 * @param root0.save - ф-ия выполняемая при сохранении
 */
export const SelectAutocomplete = ({ selectOptions, isDisabled, selectedOption, save = () => { } }) => {
    const [selectedValue, setSelectedValue] = useState(null); // выбранное значение
    const [isOpen, setIsOpen] = useState(false); // Состояние открытия списка
    const [isValueChanged, setIsValueChanged] = useState(false); // Флаг, показывающий менялось ли значение
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { palette } = useTheme();
    useEffect(() => {
        if (selectOptions.some(option => option.name === selectedOption))
            setSelectedValue(selectedOption);
    }, [selectedOption]);
    useEffect(() => {
        setIsValueChanged(selectedOption?.trim() !== selectedValue?.trim());
    }, [selectedValue, selectedOption]);
    /**
     * Функция при нажатии кнопки сохранения
     */
    const onSave = () => {
        setIsOpen(false);
        const { id } = selectOptions.filter(select => select.name === inputValue)[0];
        setIsLoading(true);
        save(id, () => setIsLoading(false));
    };
    return (React.createElement(Autocomplete, { id: 'custom-mui-autocomplete', options: selectOptions.map(sel => sel.name), disabled: isDisabled || isLoading, slots: { popper: CustomPopper }, open: isOpen, onOpen: () => setIsOpen(true), onClose: () => setIsOpen(false), disableCloseOnSelect: true, value: selectedValue, onChange: (_, newValue) => setSelectedValue(newValue), inputValue: inputValue, onInputChange: (_, newValue) => setInputValue(newValue), filterOptions: (options, state) => {
            if (options.length === 0) {
                // Добавляем опции, когда нет специальностей
                return ['Нет добавленных специальностей', 'Добавить специальность'];
            }
            return options.filter(option => option.toLocaleLowerCase().trim()
                .includes(state.inputValue.toLocaleLowerCase().trim()));
        }, renderInput: params => (React.createElement("div", { ref: params.InputProps.ref, className: 'px-3 flex justify-between' }, isLoading ? React.createElement(CircularProgress, { size: 30 }) : (React.createElement(React.Fragment, null,
            React.createElement("input", { style: {
                    width: '75%',
                    background: 'transparent',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 600,
                    outline: 'none'
                }, placeholder: isOpen ? 'Введите название специальности' : 'Не назначена', type: 'text', ...params.inputProps, className: `${params.inputProps.className} ${!isOpen && 'inputPlaceholder'}` }),
            !isOpen ? (React.createElement(IconButton, { sx: { p: 2 }, onClick: () => !isDisabled && setIsOpen(true) },
                React.createElement(IconDropdown, { style: { width: '20px', height: '20px' }, className: '!text-primary' }))) : (React.createElement(IconButton, { sx: { p: 0, pointerEvents: isValueChanged ? 'auto' : 'none' }, onClick: () => !isDisabled && isValueChanged && onSave() },
                React.createElement(IconOkay, { className: isValueChanged ? '!text-primary' : '' }))))))), renderOption: (props, option) => {
            const { key, ...optionProps } = props;
            if (option === 'Добавить специальность') {
                return (React.createElement(SettingsSpecialityButton, { label: '+ \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C', onClick: () => navigate('/settings?tab=speciality&second_tab=add'), sx: { width: '100%' } }));
            }
            return (React.createElement(ListItem, { key: key, ...optionProps, sx: {
                    height: '33px',
                    fontWeight: 500,
                    lineHeight: '100%',
                    pl: '11px !important',
                    color: palette.blue[700],
                    pointerEvents: option === 'Нет добавленных специальностей' ? 'none' : 'auto',
                    '&[aria-selected="true"]': { background: `${palette.blue[200]} !important`, pointerEvents: 'none' }
                } }, option));
        } }));
};
