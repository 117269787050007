import React, { forwardRef } from 'react';
import { Alert, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
/**
 * Компонент для вывода уведомлений пользователю
 * @param root0 - пропсы notistack
 * @param root0.id - id
 * @param root0.message - сообщение
 * @param root0.onClose - ф-ия закрытия уведомления
 * @param root0.icon - опционально, новая иконка
 * @param root0.severity - опционально, тип MUI-Alert уведомления: 'success' | 'error' | 'warning' | 'info'
 * @param root0.className - опционально, классы для MUI-Alert уведомления
 */
export const CustomAlert = forwardRef(({ id, message, onClose, icon, severity = 'success', className = '' }, ref) => {
    const theme = useTheme();
    return (React.createElement(Alert, { severity: severity, icon: icon, ref: ref, action: (React.createElement(IconButton, { onClick: () => onClose(id) },
            React.createElement(CloseIcon, { sx: { width: 18, height: 18, color: theme.palette.grey[500] } }))), sx: {
            width: 'calc(100% - 200px)',
            pl: '16px',
            pr: '8px',
            alignItems: 'center',
            '& .MuiAlert-action': { pt: 0 },
            boxShadow: theme.palette.custom.shadows[0],
            [theme.breakpoints.down('sm')]: { width: '100%' }
        }, className: className },
        React.createElement(Typography, { variant: 'body1', sx: { wordWrap: 'break-word' } }, message)));
});
