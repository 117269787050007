import { useState } from 'react';
import { useBlocker } from 'react-router-dom';
/**
 * хук для предупреждения о смене роута (переход по ссылке на сайте, нажатие назад/вперед)
 * @param root0 - props
 * @param root0.isEnabled - флаг, указывающий активен ли хук
 * @param root0.isWatchingSearchParams - флаг, указывающий отслеживаем мы только адреса страниц или и поисковые параметры тоже
 * @param root0.onBlock - Ф-ия выполнится во время блокирования
 */
export const useRouteChangeWarning = ({ isEnabled, onBlock = () => { }, isWatchingSearchParams }) => {
    const [blockedNextUrl, setBlockedNextUrl] = useState('');
    useBlocker(({ currentLocation, nextLocation }) => {
        if (!isEnabled)
            return false;
        if (!isWatchingSearchParams) {
            if (currentLocation.pathname === nextLocation.pathname)
                return false;
        }
        else if (currentLocation.pathname === nextLocation.pathname && currentLocation.search === nextLocation.search)
            return false;
        setBlockedNextUrl(nextLocation.pathname + nextLocation.search);
        onBlock();
        return true;
    });
    return { blockedNextUrl };
};
