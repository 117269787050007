import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { TableWrapper } from 'components/table/Wrapper';
import TooltipsMain from 'components/tooltips/Main';
import Loader from 'helpers/Loader';
import { PlaceholderWithImage } from 'helpers/Placeholder/WithImage';
import { SelectAutocomplete } from 'helpers/Select/Autocomplete';
import { useCustomAlerts } from 'helpers/hooks/useCustomAlerts';
import { IconChecked, IconExclamationMark, IconTooltip } from 'svg/common';
import { useAssignSpecialityMutation } from 'actions/specialities/useAssignSpecialityMutation';
import { useDoctorSpecialities } from 'actions/specialities/useDoctorSpecialities';
import { useSpecialities } from 'actions/specialities/useSpecialities';
import placeholderImage from '../../../images/placeholders/tools.webp';
// Шапка таблицы
const tableHeadAppointment = (React.createElement("tr", { className: 'text-smallest font-semibold text-gray-main' },
    React.createElement("th", { className: 'pl-6 w-[70%]' }, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0438\u0437 \u0431\u0430\u0437\u044B \u0434\u0430\u043D\u043D\u044B\u0445"),
    React.createElement("th", { className: 'flex items-center pl-3', style: { height: 'inherit' } },
        "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u043D\u0430 \u0441\u0430\u0439\u0442\u0435",
        React.createElement("div", { "data-tooltip-id": 'SettingsSpecialityAppointment', "data-tooltip-content": '\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0432\u044B \u0434\u043E\u0431\u0430\u0432\u0438\u043B\u0438 \u043D\u0430 \u0432\u043A\u043B\u0430\u0434\u043A\u0435 \u0440\u044F\u0434\u043E\u043C.\n           \u0412\u044B\u0431\u0440\u0430\u043D\u043D\u0430\u044F \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0431\u0443\u0434\u0435\u0442 \u043F\u043E\u043A\u0430\u0437\u0430\u043D\u0430 \u0432 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0435 \u0432\u0440\u0430\u0447\u0430.', className: 'h-4 pl-1.5' },
            React.createElement(IconTooltip, null)))));
/**
 * Таб "Назначение"
 */
export const SettingsSpecialityAppointment = () => {
    const [currentDoctorSpeciality, setCurrentDoctorSpeciality] = useState();
    const { dataSpecialities, isFetchingSpecialities } = useSpecialities();
    const { dataDoctorsSpecialities, isFetchingDoctorsSpecialities } = useDoctorSpecialities();
    const { mutate: assignSpecialityMutation } = useAssignSpecialityMutation();
    const { showCustomAlert } = useCustomAlerts();
    /**
     * Ф-ия для сохранения изменений после выбора "специальности на сайте"
     * @param id - Идентификатор специальности
     * @param onFinish - Функция выполнится после мутации, успешной и нет
     */
    const saveDoctorSpeciality = (id, onFinish) => {
        if (currentDoctorSpeciality) {
            assignSpecialityMutation({ id, body: { speciality: { doctor_ids: currentDoctorSpeciality?.doctor_ids } } }, {
                /**
                 * Ф-ия выполнится при успешной мутации
                 */
                onSuccess: () => {
                    showCustomAlert('Специальность успешно назначена', { className: '!text-green', icon: React.createElement(IconChecked, null) });
                },
                /**
                 * Ф-ия выполнится при ошибке мутации
                 */
                onError: () => {
                    showCustomAlert('Ошибка сохранения специальности. Попробуйте еще раз', { className: '!text-red', icon: React.createElement(IconExclamationMark, null), severity: 'error' });
                },
                /**
                 * Ф-ия выполнится в любом случае
                 */
                onSettled: () => onFinish && onFinish()
            });
        }
    };
    const placeholderAppointmentText = (React.createElement(Typography, { sx: {
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '18px'
        } }, "\u0412 \u044D\u0442\u043E\u0439 \u0432\u043A\u043B\u0430\u0434\u043A\u0435 \u0431\u0443\u0434\u0443\u0442 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u043D\u0443\u0436\u043D\u043E \u0441\u043E\u043E\u0442\u043D\u043E\u0441\u0438\u0442\u044C \u0441\u043E \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044F\u043C\u0438."));
    /**
     * Рендер таблицы
     */
    const renderTable = () => (dataDoctorsSpecialities?.specialities.length === 0 ? (React.createElement(PlaceholderWithImage, { title: '\u041D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F \u043D\u0435 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u044B', SubTitleNode: (placeholderAppointmentText), image: placeholderImage })) : (React.createElement(TableWrapper, { thead: tableHeadAppointment, wrapperClass: 'rounded-lg overflow-auto', tableClass: 'specialityList table-fixed' }, dataDoctorsSpecialities?.specialities.map(data => (React.createElement("tr", { key: data.speciality, className: 'text-gray-75 text-smaller', onClick: () => setCurrentDoctorSpeciality(data) },
        React.createElement("td", { className: 'pl-6 w-[70%]' }, data.speciality),
        React.createElement("td", { "aria-label": '\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C, \u043A\u043E\u0442\u043E\u0440\u043E\u0439 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u044D\u0442\u043E \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435', className: 'pr-3' }, isFetchingSpecialities ? React.createElement(CircularProgress, { size: 30, sx: { ml: 4 } }) : (dataSpecialities && (React.createElement(SelectAutocomplete, { selectOptions: dataSpecialities?.specialities
                .map(special => ({ name: special.name, id: special.id })), selectedOption: data.metaclinic_speciality, save: saveDoctorSpeciality }))))))))));
    return (React.createElement(Box, null,
        isFetchingDoctorsSpecialities ? React.createElement(Loader, null) : (renderTable()),
        React.createElement(TooltipsMain, { id: 'SettingsSpecialityAppointment' })));
};
