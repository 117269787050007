import React, { useState } from 'react';
import DoctorsMainTabAvatar from 'components/doctors/mainTab/Avatar';
import DoctorsMainTabInfoSection from 'components/doctors/mainTab/InfoSection';
import Loader from 'helpers/Loader';
/**
 * Таб "Основное" для страницы врача
 * @param root0 - пропсы
 * @param root0.doctorData - данные врача
 * @param root0.refetch - функция для повторного запроса данных
 * @param root0.isDataLoading - загружаются ли данные
 * @returns таб "Основное"
 */
const DoctorsMainTab = ({ doctorData, refetch, isDataLoading }) => {
    const [editField, setEditField] = useState(null); // Текущее редактируемое поле
    const [isDataSaving, setIsDataSaving] = useState(false);
    // Данные для отрисовки секций и их редактирования
    const doctorInfo = [
        { key: 'speciality', label: 'Специальность из МИС', text: doctorData?.speciality },
        { key: 'metaclinic_speciality', label: 'Специальность из MetaClinic', text: doctorData?.metaclinic_speciality },
        {
            key: 'work_experience',
            label: 'Стаж работы',
            text: doctorData?.work_experience
        },
        {
            key: 'qualification',
            label: 'Квалификация',
            text: doctorData?.qualification
        },
        {
            key: 'specialization',
            label: 'Специализация',
            text: doctorData?.specialization
        },
        {
            key: 'conferences_participation',
            label: 'Участие в конференциях',
            text: doctorData?.conferences_participation
        },
        { key: 'description', label: 'О себе', text: doctorData?.description }
    ];
    /**
     * @returns основной контент таба
     */
    const renderContent = () => (React.createElement("div", { className: 'flex p-6 my-6 mx-8 rounded-lg bg-white' }, isDataLoading ? (React.createElement(Loader, { className: 'flex-1 height-[392px]' })) : (doctorData && (React.createElement("div", { className: 'flex w-full gap-x-5' },
        React.createElement(DoctorsMainTabAvatar, { doctorId: doctorData.id, avatarUrl: doctorData.avatar, refetch: refetch, isDataSaving: isDataSaving, setIsDataSaving: setIsDataSaving }),
        React.createElement("div", { className: 'flex flex-col flex-1 gap-4' }, doctorInfo.map(info => (React.createElement(DoctorsMainTabInfoSection, { key: info.label, fieldKey: info.key, fieldLabel: info.label, fieldText: info.text ? String(info.text) : '-', refetch: refetch, editField: editField, setEditField: setEditField, doctorId: doctorData.id, isDataSaving: isDataSaving, setIsDataSaving: setIsDataSaving })))))))));
    return renderContent();
};
export default DoctorsMainTab;
