import React from 'react';
import { IconButton, styled } from '@mui/material'; // предполагается использование Material UI
/**
 * Общая кнопка контроля записи
 * @param root0 - пропсы
 * @param root0.onClick - обработчик клика
 * @param root0.isDisabled - заблокирована ли кнопка
 * @param root0.size - размер кнопки
 * @param root0.children - дочерний элемент - иконка
 */
export const ChatsMessengerAudioControlButton = ({ children, onClick, isDisabled, size = 'small' }) => {
    const className = size === 'large' ? 'large' : '';
    return (React.createElement(StyledIconButton, { className: className, onClick: onClick, disabled: isDisabled }, children));
};
const StyledIconButton = styled(IconButton)(({ theme }) => ({
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '50%',
    boxShadow: '0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1)',
    '&.large': {
        height: 48,
        width: 48
    }
}));
