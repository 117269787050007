import { classNames } from 'functions/Main';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'svg/pagination';
/**
 *Компонет пагинации
 * @param root0 - пропсы
 * @param root0.setPage - Установка страницы
 * @param root0.pageCount - Число страниц
 * @param root0.page - Текущая страница
 * @param root0.className - классы
 * @param root0.onChangePages - ф-ия, которая выполнится при смене страницы
 */
const Paginator = ({ setPage, pageCount, page, className, onChangePages = () => { } }) => {
    // Обработка клика на элемент пагинации
    const handlePageClick = (e) => {
        // В библиотеке нумерация страниц начинается с 0
        setPage(e.selected);
        onChangePages();
    };
    const renderContent = () => (React.createElement(ReactPaginate, { breakLabel: '...', onPageChange: handlePageClick, pageRangeDisplayed: 5, marginPagesDisplayed: 1, pageCount: pageCount, forcePage: page || 0, previousLabel: React.createElement(IconArrowLeft, { className: 'fill-primary' }), nextLabel: React.createElement(IconArrowRight, null), className: classNames('flex gap-4.5 justify-center items-center list-none', {}, [className || '']), pageClassName: 'cursor-pointer text-gray-47', previousLinkClassName: 'cursor-pointer', nextLinkClassName: 'cursor-pointer', activeClassName: 'text-primary', renderOnZeroPageCount: null, "data-testid": 'Paginator' }));
    return renderContent();
};
export default Paginator;
