import { apiUrls, apiWrapper } from 'config/api';
/**
 * Добавление специальности
 * @param root0 - пропсы
 * @param root0.body - тело запроса
 */
export const addSpeciality = async ({ body }) => {
    const response = await apiWrapper.post(apiUrls.specialities.list, body);
    if (!response.ok) {
        const message = response?.data?.speciality?.errors?.name.join(', ');
        if (response.status === 422)
            throw Object.assign(new Error(message), { code: 422 });
        throw new Error(message);
    }
    return response.data || {};
};
