import React from 'react';
import { Box, keyframes, Stack, Typography, useTheme } from '@mui/material';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
const firstSpinKeyFrames = keyframes `
  0% {
    width: 80px;
    height: 80px;
    opacity: 0.30;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
`;
const secondSpinKeyFrames = keyframes `
  0% {
    width: 80px;
    height: 80px;
    opacity: 0.15;
  }
  100% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }
`;
/**
 * Индикатор записи голосового сообщения
 * @param root0 - пропсы
 * @param root0.isRecording - идет ли запись
 * @param root0.recordingProgress - прогресс записи в формате 00:00:00
 */
export const ChatsMessengerAudioRecordingIndicator = ({ isRecording, recordingProgress }) => {
    const { palette } = useTheme();
    return (React.createElement(Stack, { sx: {
            alignItems: 'center',
            justifyContent: 'center'
        } },
        React.createElement(Stack, { sx: {
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center'
            } },
            isRecording && (React.createElement(React.Fragment, null,
                React.createElement(Box, { sx: {
                        position: 'absolute',
                        bgcolor: palette.primary.main,
                        borderRadius: '50%',
                        width: 80,
                        height: 80,
                        opacity: 0.15,
                        animation: `${firstSpinKeyFrames} 1.5s infinite ease-in-out`
                    } }),
                React.createElement(Box, { sx: {
                        position: 'absolute',
                        bgcolor: palette.primary.main,
                        borderRadius: '50%',
                        width: 100,
                        height: 100,
                        opacity: 0.05,
                        animation: `${secondSpinKeyFrames} 1.5s infinite ease-in-out`
                    } }))),
            React.createElement(Stack, { sx: {
                    borderRadius: '50%',
                    width: 80,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 80,
                    opacity: isRecording ? 1 : 0.2,
                    zIndex: 10,
                    bgcolor: palette.primary.main
                } },
                React.createElement(MicRoundedIcon, { sx: {
                        width: 48,
                        height: 48,
                        color: palette.common.white
                    } }))),
        React.createElement(Typography, { variant: 'subtitle2', textAlign: 'center', color: palette.grey[900], height: 22, mt: 6, mb: 5 }, recordingProgress)));
};
