import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import SettingsServiceCategories from 'components/settings/ServiceCategories';
import SettingsSpeciality from 'components/settings/Speciality/Index';
import SettingsTreatScenariosTab from 'components/settings/treatScenariosTab';
import SettingsWidget from 'components/settings/Widget';
import TabsWrapper from 'helpers/TabsWrapper';
const tabs = [
    {
        key: 'serviceCategories',
        title: 'Услуги',
        renderComponent: () => React.createElement(SettingsServiceCategories, null)
    },
    {
        key: 'treatScenarios',
        title: 'Списки',
        renderComponent: () => React.createElement(SettingsTreatScenariosTab, null)
    },
    {
        key: 'widget',
        title: 'Виджет',
        renderComponent: () => React.createElement(SettingsWidget, null)
    },
    {
        key: 'speciality',
        title: 'Специальность',
        /**
         * Компонент "Специальность"
         */
        renderComponent: () => React.createElement(SettingsSpeciality, null)
    }
];
// Страница настроек
const ViewsSettingsIndex = () => {
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const renderContent = () => (React.createElement("div", { className: 'flex-1 overflow-auto', "data-testid": 'ViewsSettingsIndex' },
        React.createElement("h1", { className: 'm-8' }, "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438"),
        React.createElement(TabsWrapper, { tabs: tabs, setTabKey: setTabKey, tabKey: tabKey })));
    return renderContent();
};
export default ViewsSettingsIndex;
