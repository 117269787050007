import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
/**
 *
 * @param root0 - пропсы
 * @param root0.title - заголовок
 * @param root0.SubTitleNode - элемент под заголовком
 * @param root0.image - картинка
 * @param root0.imgAlt - альт для картинки, по умолчанию 'Не найдено'
 */
export const PlaceholderWithImage = ({ title, SubTitleNode, image, imgAlt = 'Не найдено' }) => (React.createElement(Stack, { sx: { color: 'rgba(58, 62, 65, 1)', textAlign: 'center' } },
    React.createElement(Typography, { sx: { fontSize: '18px', fontWeight: 500, lineHeight: '21px' } }, title),
    SubTitleNode && (React.createElement(Box, { sx: { mt: '8px' } }, SubTitleNode)),
    image && (React.createElement(Box, { sx: { mt: '24px', display: 'flex', justifyContent: 'center' } },
        React.createElement("img", { src: image, alt: imgAlt })))));
