import React, { useEffect, useRef, useState } from 'react';
import ButtonsIcon from 'components/buttons/Icon';
import { IconPen, IconTick, IconTooltip } from 'svg/common';
import { handleErrors, useShowError } from 'functions/Errors';
import { apiUrls, apiWrapper } from 'config/api';
import { classNames } from 'functions/Main';
import TooltipsMain from 'components/tooltips/Main';
/**
 * Отрисовка секции с информацией о враче
 * @param root0 - пропсы
 * @param root0.fieldKey - ключ идентификатор поля, который будет отправлен на сервер
 * @param root0.fieldLabel - лейбл
 * @param root0.fieldText - текст
 * @param root0.refetch - функция для повторного запроса данных
 * @param root0.doctorId - id врача
 * @param root0.editField - текущее редактируемое поле
 * @param root0.setEditField - установить текущее редактируемое поле
 * @param root0.isDataSaving - происходит ли сейчас сохранение данных
 * @param root0.setIsDataSaving - установить состояния загрузки данных
 * @returns секция информации о враче
 */
const DoctorsMainTabInfoSection = ({ fieldKey, fieldLabel, fieldText, refetch, doctorId, editField, setEditField, isDataSaving, setIsDataSaving }) => {
    const [fieldValue, setFieldValue] = useState('');
    const [isEditing, setIsEditing] = useState(Boolean);
    const setError = useShowError();
    const inputRef = useRef(null);
    /**
     * Сохранение изменений в поле
     * @param key - ключ идентификатор поля, который будет отправлен на сервер
     */
    const saveField = async (key) => {
        setIsDataSaving(true);
        const response = await apiWrapper.patch(apiUrls.doctors.show(doctorId), { doctor: { [key]: fieldValue } });
        if (response.ok) {
            refetch();
            setEditField(null);
        }
        else {
            setError(handleErrors(response));
        }
        setIsDataSaving(false);
    };
    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    useEffect(() => {
        setIsEditing(editField === fieldKey);
    }, [editField, fieldKey]);
    /**
     * Рендер подсказки
     * @param text - текст
     */
    const renderTooltip = (text) => (React.createElement("span", { "data-tooltip-id": 'DoctorsSpeciality', "data-tooltip-content": text, className: 'h-4 pl-2' },
        React.createElement(IconTooltip, null)));
    const renderTooltipVariants = {
        speciality: renderTooltip('Специальность из МИС: Специальность назначена из медицинской информационной системы (Инфодент)'),
        metaclinic_speciality: renderTooltip('Специальность из MetaClinic: Специальность назначена в MetaClinic в разделе “Настройки”')
    };
    return (React.createElement("div", { className: classNames('flex items-center justify-between px-6 py-1', { 'bg-blue-light-opacity': isEditing }) },
        React.createElement("div", { className: 'flex flex-col gap-2 text-smaller flex-1 max-w-[762px]' },
            React.createElement("span", { className: 'text-gray-main font-semibold inline-flex items-center' },
                fieldLabel,
                fieldKey in renderTooltipVariants ? renderTooltipVariants[fieldKey] : null),
            isEditing ? (React.createElement("input", { type: 'text', value: fieldValue, ref: inputRef, onChange: e => setFieldValue(e.target.value), className: 'text-gray-85 font-medium leading-5 bg-transparent focus:outline focus:outline-0' })) : (fieldText && React.createElement("span", { className: 'text-gray-85 font-medium leading-5' }, fieldText))),
        React.createElement("div", { className: 'ml-4' },
            isEditing && (React.createElement(ButtonsIcon, { onClick: () => saveField(fieldKey), className: 'flex items-center justify-center buttonGradient w-13 h-9 rounded-lg' },
                React.createElement(IconTick, { className: '!fill-white h-5 w-5' }))),
            fieldKey !== 'speciality' && fieldKey !== 'metaclinic_speciality' && !isEditing && (React.createElement(ButtonsIcon, { disabled: isDataSaving, onClick: () => {
                    setEditField(fieldKey);
                    setFieldValue(fieldText || '');
                } },
                React.createElement(IconPen, null)))),
        React.createElement(TooltipsMain, { id: 'DoctorsSpeciality' })));
};
export default DoctorsMainTabInfoSection;
