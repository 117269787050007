import React from 'react';
import { Button } from '@mui/material';
/**
 * Кнопка для страницы настройки - специальность
 * @param root0 - пропсы
 * @param root0.label - текст кнопки
 * @param root0.onClick - ф-ия при клике по кнопке
 * @param root0.sx - стили
 */
export const SettingsSpecialityButton = ({ label, onClick = () => { }, sx }) => (React.createElement(Button, { sx: {
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'none',
        ...sx
    }, onClick: onClick, className: '!text-primary' }, label));
