import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ButtonsMain from 'components/buttons/Main';
import ButtonsText from 'components/buttons/Text';
import ModalsLayout from 'components/modals/Layout';
import { TableWrapper } from 'components/table/Wrapper';
import TooltipsMain from 'components/tooltips/Main';
import Loader from 'helpers/Loader';
import { PlaceholderWithImage } from 'helpers/Placeholder/WithImage';
import { useCustomAlerts } from 'helpers/hooks/useCustomAlerts';
import { useRouteChangeWarning } from 'helpers/hooks/Navigation';
import { IconChecked, IconExclamationMark } from 'svg/common';
import { useAddSpecialityMutation } from 'actions/specialities/useAddSpecialityMutation';
import { useSpecialities } from 'actions/specialities/useSpecialities';
import { useUpdateSpecialityMutation } from 'actions/specialities/useUpdateSpecialityMutation';
import placeholderImage from '../../../images/placeholders/tools.webp';
import { SettingsSpecialityButton } from './Button';
import { SettingsSpecialityEditDeleteTableRow } from './EditDeleteTableRow';
// Шапка таблицы
const tableHeadAdd = (React.createElement("tr", { className: 'text-smallest font-semibold text-gray-main' },
    React.createElement("th", { className: 'pl-6 w-[70%]' }, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C"),
    React.createElement("th", { className: 'w-1/5 !text-center' }, "\u041D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0435\u0439"),
    React.createElement("th", { className: 'w-[10%] !text-center' }, "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F")));
/**
 * Таб "добавление"
 */
export const SettingsSpecialityAdd = () => {
    const [isAddSpecialityMode, setIsAddSpecialityMode] = useState(false); // флаг, режим добавления специализации
    const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(''); // флаг и текст для модалки, если не "", то показываем
    const [isShowChangeRouteModal, setIsShowChangeRouteModal] = useState(false); // флаг, показать модалку переключения страниц
    const [shouldNavigate, setShouldNavigate] = useState(false); // флаг, можно ли переключать на другой урл
    const { dataSpecialities, isFetchingSpecialities } = useSpecialities();
    const { showCustomAlert } = useCustomAlerts();
    const { mutate: updateSpecialityMutation } = useUpdateSpecialityMutation();
    const { mutate: addSpecialityMutation } = useAddSpecialityMutation();
    const navigate = useNavigate();
    const { blockedNextUrl } = useRouteChangeWarning({
        isEnabled: isAddSpecialityMode,
        isWatchingSearchParams: true,
        /**
         *
         */
        onBlock: () => {
            setIsShowChangeRouteModal(true);
        }
    });
    useEffect(() => {
        if (!isAddSpecialityMode && shouldNavigate) {
            closeChangeRouteModal();
            navigate(blockedNextUrl);
        }
    }, [isAddSpecialityMode, shouldNavigate, navigate]);
    /**
     * Ф-ия обновления названия специальности
     * @param newName - новое название
     * @param addFuncs - опциональные доп. функции
     */
    const addSpeciality = (newName, addFuncs) => {
        addSpecialityMutation({ body: { speciality: { name: newName } } }, {
            /**
             * Ф-ия onSuccess выполнится при успешной мутации
             */
            onSuccess: () => {
                showCustomAlert('Специальность успешно добавлена', { className: '!text-green', icon: React.createElement(IconChecked, null) });
                setIsAddSpecialityMode(false);
                if (addFuncs?.onSuccess)
                    addFuncs?.onSuccess();
            },
            /**
             * ф-ия onError выполнится при ошибке
             * @param e - ошибка
             */
            onError: (e) => {
                if (e?.code === 422) {
                    setIsShowDuplicateModal(e.message);
                }
                else {
                    showCustomAlert(`${e.message}. Попробуйте еще раз`, { className: '!text-red', icon: React.createElement(IconExclamationMark, null), severity: 'error' });
                }
                if (addFuncs?.onError)
                    addFuncs?.onError();
            },
            /**
             * ф-ия onSettled выполнится при любом исходе
             */
            onSettled: () => addFuncs?.onSettled && addFuncs?.onSettled()
        });
    };
    /**
     * Ф-ия обновления названия специальности
     * @param id - id специальности
     * @param newName - новое название
     * @param addFuncs - опциональные доп. функции
     */
    const updateSpeciality = (id, newName, addFuncs) => {
        updateSpecialityMutation({ id, body: { speciality: { name: newName } } }, {
            /**
             * Ф-ия выполнится при успешной мутации
             */
            onSuccess: () => {
                showCustomAlert('Изменения успешно сохранены', { className: '!text-green', icon: React.createElement(IconChecked, null) });
                if (addFuncs?.onSuccess)
                    addFuncs?.onSuccess();
            },
            /**
             * ф-ия выполнится при ошибке
             */
            onError: () => addFuncs?.onError && addFuncs?.onError(),
            /**
             * ф-ия выполнится при любом исходе
             */
            onSettled: () => addFuncs?.onSettled && addFuncs?.onSettled()
        });
    };
    /**
     * Функция закрывает модалку про дублирование специальности
     */
    const closeDuplicateModal = () => {
        setIsShowDuplicateModal('');
    };
    /**
     * Функция закрывает модалку про смену роута
     */
    const closeChangeRouteModal = () => {
        setIsShowChangeRouteModal(false);
    };
    /**
     * Ф-ия для перехода на другой урл, когда стоит блокировка
     */
    const handleExit = () => {
        setIsAddSpecialityMode(false);
        setShouldNavigate(true);
    };
    const placeholderAddText = (React.createElement(Typography, { sx: {
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '18px'
        } },
        "\u0412 \u044D\u0442\u043E\u0439 \u0432\u043A\u043B\u0430\u0434\u043A\u0435 \u0431\u0443\u0434\u0443\u0442 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0434\u043E\u0431\u0430\u0432\u044F\u0442\u0441\u044F \u0432 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0443 \u0432\u0440\u0430\u0447\u0430 \u043D\u0430 \u0441\u0430\u0439\u0442\u0435.",
        React.createElement("br", null),
        "\u0412\u043E \u0432\u043A\u043B\u0430\u0434\u043A\u0435 \u201C\u041D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435\u201D \u043E\u0431\u044A\u0435\u0434\u0438\u043D\u0438\u0442\u0435 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0432\u0440\u0430\u0447\u0430 \u043D\u0430 \u0441\u0430\u0439\u0442\u0435 \u0438 \u0432 \u0431\u0430\u0437\u0435 \u0434\u0430\u043D\u043D\u044B\u0445."));
    /**
     * Функция рендера таблицы специальностей
     */
    const renderTable = () => ((dataSpecialities?.specialities.length === 0 && !isAddSpecialityMode) ? (React.createElement(PlaceholderWithImage, { title: '\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u043D\u0435 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u044B', SubTitleNode: (placeholderAddText), image: placeholderImage })) : (React.createElement(TableWrapper, { thead: tableHeadAdd, tableClass: 'specialityList table-fixed', wrapperClass: 'rounded-lg overflow-auto' },
        isAddSpecialityMode && dataSpecialities && (React.createElement(SettingsSpecialityEditDeleteTableRow, { allSpeciality: dataSpecialities?.specialities, speciality: { name: '', id: 'addSpeciality' }, addSpeciality: addSpeciality, countSpecialities: 0, isAddNewSpeciality: true })),
        dataSpecialities?.specialities.map(data => (React.createElement(SettingsSpecialityEditDeleteTableRow, { key: data.id, allSpeciality: dataSpecialities?.specialities.filter(d => data.name !== d.name), speciality: data, saveEdit: updateSpeciality, countSpecialities: data.assigned_specialities_count }))))));
    return (React.createElement(Box, null,
        isFetchingSpecialities ? React.createElement(Loader, null) : (React.createElement(React.Fragment, null,
            React.createElement(Box, { sx: {
                    display: 'flex', alignItems: 'center', justifyContent: 'center', height: '38px', my: '16px'
                } },
                React.createElement(SettingsSpecialityButton, { label: '+ \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C', onClick: () => setIsAddSpecialityMode(!isAddSpecialityMode) })),
            renderTable())),
        React.createElement(ModalsLayout, { isOpen: !!isShowDuplicateModal, onClose: closeDuplicateModal, title: isShowDuplicateModal, titleClassName: 'text-center mb-4 text-2xl' },
            React.createElement("p", { className: 'text-center text-lg leading-5' },
                "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043B\u0438\u0431\u043E \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u043D\u043E\u0432\u0443\u044E,",
                React.createElement("br", null),
                " \u043B\u0438\u0431\u043E \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u044E\u0449\u0443\u044E"),
            React.createElement(ButtonsMain, { className: 'mt-8 px-12 w-auto', onClick: closeDuplicateModal }, "\u041F\u043E\u043D\u044F\u0442\u043D\u043E")),
        React.createElement(ModalsLayout, { isOpen: isShowChangeRouteModal, onClose: closeChangeRouteModal, title: '\u0412\u044B \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0432\u044B\u0439\u0442\u0438 \u0438\u0437 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u044F \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438?', titleClassName: 'text-center mb-4 text-2xl' },
            React.createElement("p", { className: 'text-center text-lg leading-5' }, "\u0412\u0430\u0448\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043D\u0435 \u0431\u0443\u0434\u0443\u0442 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u044B"),
            React.createElement(Stack, { direction: 'row', sx: { mt: '32px', gap: '16px' } },
                React.createElement(ButtonsMain, { className: 'w-[150px]', onClick: closeChangeRouteModal }, "\u041E\u0441\u0442\u0430\u0442\u044C\u0441\u044F"),
                React.createElement(ButtonsText, { className: 'w-[150px] border-red border border-solid bg-white text-red rounded-[48px] hover:text-red', onClick: () => handleExit() }, "\u0412\u044B\u0439\u0442\u0438"))),
        React.createElement(TooltipsMain, { id: 'SettingsSpecialityAdd' })));
};
