import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apiUrls } from 'config/api';
import { updateSpeciality } from './updateSpeciality';
/**
 * Мутация для обновления специальности
 */
export const useUpdateSpecialityMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        /**
         * Мутация для обновления специальности
         * @param id - Идентификатор специальности
         * @param body - тело запроса
         */
        mutationFn: updateSpeciality,
        /**
         * Обработка ошибки мутации
         * @param error Ошибка
         */
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
        /**
         * onSuccess вызывается после успешной мутации
         */
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [apiUrls.specialities.list] }); // Инвалидируем список специальностей
        }
    });
};
