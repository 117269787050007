import { apiUrls, apiWrapper } from 'config/api';
import { handleErrors } from 'functions/Errors';
/**
 * Обновление специальности
 * @param root0 - пропсы
 * @param root0.id - Идентификатор специальности
 * @param root0.body - тело запроса
 */
export const updateSpeciality = async ({ id, body }) => {
    const response = await apiWrapper.patch(apiUrls.specialities.updateSpeciality(id), body);
    if (!response.ok) {
        const error = handleErrors(response);
        throw new Error(error);
    }
    return response.data || {};
};
